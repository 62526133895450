<template>
  <div>
    <Loader v-if="loader" />
    <md-table v-model="searched" md-sort="category" md-sort-order="asc" v-else>
      <md-table-toolbar>
        <div class="md-list-item-text space-down">
          <span class="md-display-1 title-fix">Categorías</span>
          <span class="md-caption">Listado de Categorías registradas</span>
          <div class="md-layout md-alignment-left margin-fix">
            <div class="md-layout-item"></div>
            <div class="md-layout-item md-size-80">
              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Busca por nombre de categoría" v-model="search" @input="searchOnTable" />
              </md-field>
            </div>
          </div>
        </div>
        <Create />
      </md-table-toolbar>
      <md-table-row>
        <md-table-head md-numeric>ID</md-table-head>
        <md-table-head>Código</md-table-head>
        <md-table-head>Categoría</md-table-head>
        <md-table-head>Tipo</md-table-head>
        <md-table-head>Editar</md-table-head>
        <md-table-head>Eliminar</md-table-head>
      </md-table-row>
      <md-table-row v-if="list.length < 1">
        <Empty />
      </md-table-row>
      <md-table-row v-for="item in filteredItems" v-else slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-numeric>{{item.id}}</md-table-cell>
        <md-table-cell>{{item.code}}</md-table-cell>
        <md-table-cell md-sort-by="category">{{item.category}}</md-table-cell>
        <md-table-cell v-if="item.type == 1">Aplicación</md-table-cell>
        <md-table-cell v-if="item.type == 2">Producto</md-table-cell>
        <md-table-cell>
          <Edit :data="item" />
        </md-table-cell>
        <md-table-cell>
          <md-button class="md-icon-button md-primary" @click="deleteCategory(item.id)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import Create from '@/components/Categories/Create.vue'
import Loader from '@/components/Common/Loader.vue'
import Edit from '@/components/Categories/Edit.vue'
import Empty from '@/components/Common/Empty.vue'
import filter from '@/mixins/filter'
import { mapActions } from 'vuex'
export default {
  name: 'Categories',
  mixins: [filter],
  components: {
    Edit, Create, Loader, Empty
  },
  data() {
    return {
      loader: true
    }
  },
  computed: {
    list: {
      get() {
        return this.$store.getters['categories/listCategories']
      },
      set(value) {
        this.searched = value
      }
    },
    filteredItems() {
      if(this.search.length > 0) {
        return this.searchByName(this.list, this.search, 'category')
      }
      return this.list
    }
  },
  created() {
    this.categories().then(() => {
      this.loader = false
    })
  },
  methods: {
    ...mapActions({categories: 'categories/getCategories', deleteCategory: 'categories/deleteCategory'}),
  }
}
</script>
