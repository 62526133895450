<template>
  <div>
    <md-button @click="showDialog = true">Crear nuevo</md-button>
    <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>Nueva Categoría</md-dialog-title>
    <div class="md-layout md-alignment-center margin-fix">
      <p>Introduzca código, nombre y tipo para la categoría</p>
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Código de categoría</label>
          <md-input v-model="$v.category.code.$model" />
        </md-field>
        <div v-if="$v.category.code.$dirty">
          <span class="md-helper-text" v-if="!$v.category.code.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.category.code.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.category.code.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Nombre de categoría</label>
          <md-input v-model="$v.category.category.$model" />
        </md-field>
        <div v-if="$v.category.category.$dirty">
          <span class="md-helper-text" v-if="!$v.category.category.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.category.category.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.category.category.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Aplica a</label>
          <md-select v-model="$v.category.type.$model">
            <md-option v-for="type in types" :value="type.value">{{type.text}}</md-option>
          </md-select>
        </md-field>
        <div v-if="$v.category.type.$dirty">
          <span class="md-helper-text" v-if="!$v.category.type.required">Campo requerido</span>
        </div>
      </div>
    </div>
    <md-dialog-actions class="margin-fix">
      <md-button class="md-primary" @click="onCancel">Cerrar</md-button>
      <md-button class="md-primary" @click="onConfirm">Crear</md-button>
    </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import validations from '@/validations/category'
import categories from '@/mixins/categories'
export default {
  name: 'Create',
  mixins: [categories],
  data() {
    return {
      showDialog: false,
      category: {
        code: '',
        category: '',
        type: ''
      }
    }
  },
  mounted() {
    Object.assign(this.$data, this.$options.data.apply(this))
  },
  validations: validations,
  methods: {
    onConfirm () {
      if (!this.$v.$invalid) {
        this.$store.dispatch('categories/addCategory', this.category)
        this.showDialog = false
        this.cleanUp()
      }
    },
    cleanUp() {
      this.$data.category = this.$options.data.apply(this).category
      this.$v.$reset()
    },
    onCancel () {
      this.showDialog = false
    }
  }
}
</script>
