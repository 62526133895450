import {required, maxLength, minLength, between} from 'vuelidate/lib/validators'

export default {
  category: {
    code: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(25)
    },
    category: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(25)
    },
    type: {
      required,
      between: between(1,2)
    }
  }
}
